import React, { useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Form, TextArea, Input, Button, Select } from 'semantic-ui-react';
import Recaptcha from 'react-recaptcha';
import { useRef } from 'react';

const ContactPage = () => {
  const recaptchaRef = useRef(null);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [purpose, setPurpose] = useState('');

  const purposeOptions = [
    { key: 'f', text: 'General Feedback', value: 'General Feedback' },
    { key: 'j', text: 'Looking for Job', value: 'Looking for Job' },
    {
      key: 'l',
      text: 'Looking for a bartender',
      value: 'Looking for a bartender',
    },
    {
      key: 'c',
      text: 'New Cocktail suggestion',
      value: 'New Cocktail suggestion',
    },
    {
      key: 'e',
      text: 'Update suggestion for a cocktail recipe',
      value: 'Update suggestion for a cocktail recipe',
    },
  ];

  const verifyCallback = (res) => {
    if (res) {
      setAllowSubmit(true);
    }
  };
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!allowSubmit) return;
    setAllowSubmit(false);
    recaptchaRef.current.reset();
    fetch('https://play.review.com.np/contact/cocktail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, purpose, subject, text: message, email }),
    }).then(() => {
      alert('Message has been sent!');
      setShowForm(false);
      setEmail('');
      setName('');
      setMessage('');
      setPurpose('');
      setSubject('');
    });
  };

  return (
    <Layout>
      <SEO title="Contact Form" />
      <h1>Contact</h1>

      {showForm ? (
        <>
          <p>
            You have a suggestion of a new cocktail?
            <br /> Are you looking for a Bartender for your business or are
            looking to work as a bartender?
            <br /> Or you have a general feedback for us.
          </p>
          <p>
            Contact us by fillig up the form below and we will get back to you.
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-control-first-name"
                control={Input}
                label="Full name"
                placeholder="Full name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Field
                control={Select}
                options={purposeOptions}
                required
                label={{
                  children: 'Purpose',
                  htmlFor: 'form-select-control-purpose',
                }}
                placeholder="Purpose"
                search
                searchInput={{
                  id: 'form-select-control-purpose',
                }}
                value={purpose}
                onChange={(e) => setPurpose(e.target.textContent)}
              />
            </Form.Group>
            <Form.Field
              id="form-input-control-first-name"
              control={Input}
              label="Subject"
              placeholder="Subject"
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <Form.Field
              id="form-textarea-control-opinion"
              control={TextArea}
              label="Message"
              placeholder="Message"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Form.Field
              id="form-input-control-error-email"
              control={Input}
              label="Email"
              placeholder="john@somesite.com"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Recaptcha
              ref={recaptchaRef}
              sitekey="6LfL0fIUAAAAAAGGmJAZYSjPHZC8tox6bh_bNRV5"
              verifyCallback={verifyCallback}
            />
            <Form.Field
              id="form-button-control-public"
              control={Button}
              content="Send"
            />
          </Form>
        </>
      ) : (
        <p>Thank you for contacting us!</p>
      )}
    </Layout>
  );
};

export default ContactPage;
